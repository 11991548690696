
import UserCredentials   from "../../services/auth/UserCredentials";
import {defineComponent} from "@vue/runtime-core";

export default defineComponent({
  name      : 'Login',
  data() {
    return {
      user   : new UserCredentials('', ''),
      loading: false,
      message: ''
    };
  },
  methods   : {
    handleLogin() {
      this.loading = true;

      if (this.user.username && this.user.password) {
        // @ts-ignore
        this.$auth.login({
          data: this.user
        })
      }
    }
  }
});
