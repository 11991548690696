<template>
  <form class="uk-form-stacked" @submit.prevent="">

    <div class="uk-margin">
      <label class="uk-form-label" for="login">Логин</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="login" type="text" v-model="user.username" >
      </div>
    </div>

    <div class="uk-margin">
      <label class="uk-form-label" for="password">Пароль</label>
      <div class="uk-form-controls">
        <input class="uk-input" id="password" type="password" v-model="user.password" >
      </div>
    </div>

    <button class="uk-button uk-button-primary" @click="handleLogin">Сохранить</button>

  </form>
</template>

<script lang="ts">
import UserCredentials   from "../../services/auth/UserCredentials";
import {defineComponent} from "@vue/runtime-core";

export default defineComponent({
  name      : 'Login',
  data() {
    return {
      user   : new UserCredentials('', ''),
      loading: false,
      message: ''
    };
  },
  methods   : {
    handleLogin() {
      this.loading = true;

      if (this.user.username && this.user.password) {
        // @ts-ignore
        this.$auth.login({
          data: this.user
        })
      }
    }
  }
});
</script>